import { useEffect, useRef, useState } from "react";
import CTAFour from "../components/cta/CTAFour";
import CTAThree from "../components/cta/CTAThree";
import CTATwo from "../components/cta/CTATwo";
import ColoredCTA from "../components/cta/ColoredCTA";
import { CTAOne } from "../components/cta/CtaOne";
import DarkCTA from "../components/cta/DarkCTA";
import { FeaturesFour } from "../components/features/FeaturesFour";
import { FeaturesOne } from "../components/features/FeaturesOne";
import { FeaturesOneWithImage } from "../components/features/FeaturesOneWithImage";
import FeaturesThree from "../components/features/FeaturesThree";
import { FeaturesTwoWithImage } from "../components/features/FeaturesTwoWithImage";
import ColoredFooter from "../components/footer/ColoredFooter";
import FooterOne from "../components/footer/FooterOne";
import { Header } from "../components/header/Header";
import HeaderFive from "../components/header/HeaderFive";
import HeaderThree from "../components/header/HeaderThree";
import { Hero } from "../components/hero/Hero";
import HeroFive from "../components/hero/HeroFIve";
import HeroFour from "../components/hero/HeroFour";
import HeroThree from "../components/hero/HeroThree";
import HeroTwo from "../components/hero/HeroTwo";
import StatsOne from "../components/stats/StatsOne";
import StatsTwo from "../components/stats/StatsTwo";
import { TestimonialOne } from "../components/testimonials/TestimonialOne";
import { TestimonialTwo } from "../components/testimonials/TestimonialTwo";
import { TickerTape } from "react-ts-tradingview-widgets";

const Main = () => {
  useEffect(() => {
    function removeTickers() {
      const ticker = document.querySelectorAll("#tradingview_widget_wrapper");
      ticker?.forEach((tick) => {
        tick.lastChild.style.display = "none";
      });
    }

    removeTickers();

    return () => {
      removeTickers();
    };
  });

  const tickerSymbols = [
    {
      proName: "BITSTAMP:BTCUSD",
      title: "BTC/USD",
    },
    {
      proName: "BITSTAMP:ETHUSD",
      title: "ETH/USD",
    },
    {
      proName: "FOREXCOM:SPXUSD",
      title: "S&P 500",
    },
    {
      proName: "FOREXCOM:NSXUSD",
      title: "Nasdaq 100",
    },
    {
      proName: "FX_IDC:EURUSD",
      title: "EUR/USD",
    },
  ];

  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    const playVideo = () => {
      if (videoRef.current) {
        videoRef.current.play().catch((error) => {
          console.error("Autoplay was prevented:", error);
        });
      }
    };

    // Try to play the video when the component mounts
    playVideo();

    // Add event listeners for user interaction
    const handleInteraction = () => {
      if (!isPlaying) {
        playVideo();
        setIsPlaying(true);
      }
    };

    document.addEventListener("touchstart", handleInteraction);
    document.addEventListener("click", handleInteraction);

    return () => {
      document.removeEventListener("touchstart", handleInteraction);
      document.removeEventListener("click", handleInteraction);
    };
  }, [isPlaying]);

  return (
    <>
      <div style={{ backgroundColor: "#0c0f19", position: "relative" }}>
        <video
          ref={videoRef}
          loop
          muted
          playsInline
          className="absolute top-0 left-0 w-full h-full object-cover z-0"
        >
          <source src="/bg-video.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50 z-5"></div>
        <Header />
        <Hero />
      </div>
      <TickerTape colorTheme="light" symbols={tickerSymbols}></TickerTape>
      <FeaturesOne />
      <div
        style={{
          backgroundColor: "#0c0f19",
          padding: "12px 0px",
        }}
      >
        <StatsTwo />
      </div>
      <TestimonialOne />
      <DarkCTA />
      <FooterOne />
      {/* <FeaturesTwoWithImage /> */}
      {/* <HeroFive /> */}
      {/* <HeaderFive /> */}
      {/* <HeaderThree /> */}
      {/* <HeroFour /> */}
      {/* <HeroThree /> */}
      {/* <FeaturesTwoWithImage /> */}
      {/* <FeaturesThree /> */}
      {/* <StatsOne /> */}

      {/* <TestimonialTwo /> */}
      {/* <div style={{ backgroundColor: "#0c0f19" }}>
        <CTATwo />
      </div> */}
      {/* <CTATwo /> */}
      {/* <CTAOne /> */}
      {/* <CTAFour /> */}
      {/* <CTAThree /> */}
    </>
  );
};

export default Main;
